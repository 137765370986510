<template>
  <footer>
    <div class="footer-row">
      <div v-if="lang !== 'en'">
        {{ t('DO_YOU_HAVE_ANY_QUESTIONS') }}
        <a target="_blank" :href="sanitizeUrl(t('CONTACT_URL'))">
          {{ t('GET_IN_TOUCH') }}
        </a>
      </div>
      <div v-else>
        {{ t('DO_YOU_HAVE_ANY_QUESTIONS') }}
        <div class="footer-row-en">
          <a target="_blank" href="https://www.postnord.se/kontakta-oss">
            PostNord SE
          </a>
          <a target="_blank" href="https://www.postnord.fi/asiakaspalvelu">
            PostNord FI
          </a>
          <a target="_blank" href="https://www.postnord.dk/kundeservice">
            PostNord DK
          </a>
        </div>
      </div>
    </div>
    <div class="footer-row">
      <p>
        {{ t('MORE_INFORMATION') }}
        <a target="_blank" :href="sanitizeUrl(t('DOMAIN_URL'))">
          {{ t('DOMAIN_URL').slice(12) }}
        </a>
      </p>
    </div>
    <div class="footer-row">
      <a target="_blank" :href="sanitizeUrl(t('PRIVACY_POLICY_URL'))" style="margin-left: 0.5em">
        {{ t('PRIVACY_POLICY') }}
      </a>
      <a target="_blank" :href="sanitizeUrl(t('COOKIES_URL'))" style="margin-left: 0.5em">
        {{ t('COOKIES') }}
      </a>
    </div>
  </footer>
</template>

<script setup lang="ts">
import i18n, { t } from 'i18next'
import { sanitizeUrl } from '@braintree/sanitize-url'
import { ref } from 'vue'

const lang = ref(i18n.resolvedLanguage)
</script>

<style lang="scss" scoped>
footer {
  color: $gray700;
  padding-bottom: 2em;
  display: block;

  .footer-row {
    padding: 0.2em 0;
    font-size: 0.9em;
    text-align: center;

    p,
    a {
      margin-right: 0.2em;
      display: inline-block;
    }
  }

  .footer-row-en {
    margin-top: 0.2em;
    display: flex;
    justify-content: center;
    gap: 0.5em;

    a {
      display: inline-block;
      padding: 0.2em 0;
    }
  }

  a {
    color: $blue700;
  }
}

#ot-sdk-btn {
  -webkit-box-direction: normal;
  box-sizing: border-box;
  color: $blue700;
  display: inline-block;
  font-size: 0.9em;
  margin-right: 0.2em;
  text-align: center;
  text-decoration: none;
  visibility: inherit;
}

@media only screen and (max-width: 880px) {
  footer {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
</style>
